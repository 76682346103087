import React from "react"
import { Box } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import Player from "./player"

const AssetModule = ({ data }) => {
  let cols = data.cols
  if (!cols) {
    cols = (data.assets || []).length === 1 ? 1 : 2
  }

  return (
    <Box>
      {data.vimeoId && (
        <Player
          showPlay={true}
          id={data.vimeoId}
          aspectRatio={"2.39:1"}
          thumbnail={data.vimeoThumbnail}
          vimeoId={data.vimeoId}
        />
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${cols}, 1fr)`,
          gridRowGap: "2px",
          gridColumnGap: "2px",
          width: "100%",
        }}
      >
        {(data.assets || []).map((asset) => {
          if (asset.file.contentType.includes("mp4")) {
            return (
              <Box
                key={asset.id}
                sx={{
                  width: "100%",
                  video: {
                    width: "100%",
                  },
                }}
              >
                <Player
                  autoplay={true}
                  url={asset.file.url}
                  disableInteraction={true}
                  aspectRatio={null}
                  showPlay={true}
                  id={asset.file.fileName.split(".")[0]}
                />
              </Box>
            )
          } else {
            return (
              <Box key={asset.id}>
                <GatsbyImage image={asset.gatsbyImageData} alt="" />
              </Box>
            )
          }
        })}
      </Box>
    </Box>
  )
}

export default AssetModule
