import { Box, Flex, Text } from "@theme-ui/components"
import { Link, graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useContext, useEffect } from "react"

import AssetModule from "../components/asset-module"
import Layout from "../components/layout"
import PeopleModule from "../components/people-module"
import Player from "../components/player"
import SEO from "../components/seo"
import TextModule from "../components/text-module"

import { InterfaceContext } from "../context/interface"

import Caret from "../assets/caret.png"
import BlondeTyped from "../assets/logo-typed-positive.svg"

const PageTemplate = ({ data, pageContext }) => {
  let { footerNav, caseContent } = data

  const { setTranslationLinks } = useContext(InterfaceContext)
  
  useEffect(() => {
    setTranslationLinks(pageContext.canonicals)
  }, [])
  const handleRenderCaseContent = () => {
    if (caseContent.vimeoId) {
      return (
        <Player
          showPlay
          id={caseContent.slug}
          thumbnail={caseContent.thumbnail}
          aspectRatio={"2.39:1"}
          vimeoId={caseContent.vimeoId}
        />
      )
    }

    if (caseContent.bannerStills) {
      return (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridColumnGap: "10px",
          }}
        >
          {caseContent.bannerStills.map((s) => (
            <GatsbyImage
              image={s.gatsbyImageData}
              key={s.id}
              height="100%"
              flex="1"
              alt=""
            />
          ))}
        </Box>
      )
    }

    if (caseContent.mainContent) {
      return (
        <img
          style={{
            height: "100%",
            objectFit: "cover",
            display: "block",
          }}
          src={caseContent?.mainContent?.file.url}
          loading="lazy"
          alt=""
        />
      )
    }
    return null
  }
  const [lang] = pageContext.locale.split("-")
  let prefix = "/"
  if (lang !== "en") {
    prefix = `/${lang}/`
  }
  const now = new Date()
  return (
    <Layout
      prefix={prefix}
      dropup={data.dropup}
      subNav={data.subnav}
      nav={data.navigation} locale={pageContext.locale}>
      <SEO
        locale={pageContext.locale}
        canonicals={pageContext.canonicals}
        title={`${caseContent.title} - ${caseContent.clientName}`}
        description={
          caseContent.description && caseContent.description.childMarkdownRemark.html
        }
      />
      <Flex sx={{ flexDirection: "column" }}>
        <Box sx={{ width: "100%", maxHeight: "75vh" }}>{handleRenderCaseContent()}</Box>
        <Flex
          m="auto"
          px="40px"
          py="100px"
          sx={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: "1200px",
          }}
        >
          <Flex
            sx={{ cursor: "pointer", width: "100%", alignItems: "center" }}
            mb={3}
          >
            <Box
              sx={{
                height: "10px",
                img: { transform: "rotate(180deg)", height: "100%" },
              }}
              mr={2}
            >
              <img src={Caret} />
            </Box>

            <Box
              sx={{
                fontSize: "12px",
                color: "#2A2C3",
                a: {
                  textDecoration: "none",
                },
              }}
            >
              <Link
                to={`../../featured-cases`}>
                Back
              </Link>
            </Box>
          </Flex>
          <Flex>
            <Box sx={{ width: "100%", maxWidth: "500px" }}>
              <Box mb={"13px"}>
                <Box
                  mb="5px"
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    fontWeight: "200",
                    fontSize: "12px",
                    lineHeight: "13px",
                    textTransform: "uppercase",
                  }}
                >
                  Client
                </Box>
                <Box
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    fontSize: "12px",
                    lineHeight: "13px",
                    fontWeight: "400",
                  }}
                >
                  {caseContent.clientName}
                </Box>
              </Box>
              <Box mb={23}>
                <Box
                  mb="5px"
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    fontWeight: "200",
                    fontSize: "12px",
                    lineHeight: "13px",
                    textTransform: "uppercase",
                  }}
                >
                  Case
                </Box>
                <Box
                  sx={{
                    fontFamily: "pragmatica-extended, sans-serif",
                    h1: {
                      fontWeight: "400",
                      fontSize: "12px",
                      my: "0",
                      lineHeight: "13px",
                    }
                  }}
                >
                  <h1>{caseContent.title}</h1>
                </Box>
              </Box>
              <Box
                sx={{
                  fontSize: "12px",
                  whiteSpace: "pre-wrap",
                }}
              ></Box>
            </Box>
            <Box sx={{ width: "100%", height: "100%" }}>
              <Flex>
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "2px",
                    height: "3px",
                    backgroundColor: caseContent.color1,
                  }}
                />
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "2px",
                    height: "3px",
                    backgroundColor: caseContent.color2,
                  }}
                />
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "2px",
                    height: "3px",
                    backgroundColor: caseContent.color3,
                  }}
                />
              </Flex>
            </Box>
          </Flex>
          <Flex>
            <Box sx={{fontFamily: "Formular" }}>
              {caseContent.description && (
                <div dangerouslySetInnerHTML={{ __html: caseContent.description.childMarkdownRemark.html }} />
              )}
            </Box>
          </Flex>
        </Flex>
        <Box
          sx={{
            ".peo-mod + .peo-mod": {
              paddingTop: 0,
            },
          }}
        >
          {(caseContent.modules || []).map((module) => {
            if (!module?.internal?.type) {
              return null
            }

            if (module.internal.type === "ContentfulSpacer") {
              const val = module.spacing || 50
              return <Box key={module.id} pb={[val / 2.5, val]} />
            }

            if (module.internal.type === "ContentfulTextModule") {
              module.columns?.forEach((col) => col.enlarge = false)
              return <TextModule key={module.id} data={module} />
            }
            if (module.internal.type === "ContentfulAssetModule") {
              return <AssetModule key={module.id} data={module} />
            }
            if (module.internal.type === "ContentfulPeopleModule") {
              return <PeopleModule padTop={true} padBottom={true} key={module.id} data={module} />
            }

            return null
          })}
        </Box>
        <Flex mb={2} py={["50px", "150px"]}>
          <Flex
            m="auto"
            mx={"auto"}
            px={[3, "40px"]}
            sx={{
              maxWidth: "1200px",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Flex
              mb={"20px"}
              sx={{ width: "100%", alignItems: "center", cursor: "pointer" }}
            >
              <Flex
                sx={{ flex: 1, alignItems: "center", cursor: "pointer" }}
              >
                <Box
                  sx={{
                    height: "10px",
                    img: { transform: "rotate(180deg)", height: "100%" },
                  }}
                  mr={2}
                >
                  <img src={Caret} />
                </Box>

                <Box
                  sx={{
                    fontSize: "12px",
                    color: "#2A2C3",
                    a: {
                      textDecoration: "none",
                    },
                  }}
                >
                  <Link
                    to={`../../featured-cases`}>
                    Back
                  </Link>
                </Box>
              </Flex>
              <Box sx={{ height: "15px" }}>
                <img
                  style={{ width: "auto" }}
                  height="100%"
                  src={BlondeTyped}
                />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: ["column-reverse", "row"],
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Flex
                sx={{
                  justifyContent: "space-between",
                  flex: ["1", "unset"],
                }}
              >
                <Flex
                  sx={{
                    flex: ["1", "unset"],
                    flexDirection: "column",
                    justifyContent: ["flex-end", "space-between"],
                  }}
                >
                  <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                    Copyright © {now.getFullYear()}
                  </Box>
                  <Box sx={{ fontSize: 0, textTransform: "uppercase" }}>
                    All Rights Reserved
                  </Box>
                </Flex>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Flex
                    px={[0, "20px"]}
                    mb={[2, 0]}
                    sx={{
                      fontSize: [1, 0],
                      textTransform: "uppercase",
                      maxWidth: "200px",
                      div: {
                        marginRight: "3px",
                        marginLeft: "3px",
                        textDecoration: "none",
                      },
                    }}
                  ></Flex>
                  <Flex
                    px={[0, "20px"]}
                    mb={[2, 0]}
                    sx={{
                      fontSize: [1, 0],
                      textTransform: "uppercase",
                      maxWidth: "200px",
                      a: {
                        marginRight: "3px",
                        marginLeft: "3px",
                        textDecoration: "none",
                      },
                    }}
                  >
                    <a href="https://instagram.com/blonde___inc">Instagram</a>/
                    <a href="https://vimeo.com/blondeinc">Vimeo</a>/
                    <a href="https://linkedin.com/company/blondeinc">
                      LinkedIn
                    </a>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                sx={{
                  flex: [1, "unset"],
                  justifyContent: ["space-between", "flex-start"],
                  flexDirection: ["row", "row"],
                  fontWeight: "400",
                  fontSize: [2, 1],
                  textTransform: "uppercase",
                  a: {
                    marginBottom: [2, 0],
                    textDecoration: "none",
                    ":not(:last-of-type)": {
                      marginRight: "30px",
                    },
                  },
                }}
              >
                {footerNav.items.map((i) => (
                  <Link to={`${prefix}${i.slug}/`}>{i.title}</Link>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query CaseContent($slug: String!, $locale: String!) {
    subnav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Subnavigation" }
    ) {
      ...navigationFields
    }
    dropup: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Services" }
    ) {
      ...navigationFields
    }
    navigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      ...navigationFields
    }
    footerNav: contentfulNavigationMenu(
        name: { eq: "Footer" }
        node_locale: { eq: $locale }
      ) {
      items {
        ...navigationItemFields
      }
    }
    caseContent: contentfulCase(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...casePreviewFields
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      modules {
        ... on ContentfulAssetModule {
          id
          cols: columns
          assets {
            file{
              contentType
              url
              fileName
            }
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
          internal {
            type
          }
        }
        ... on ContentfulTextModule {
          id
          columns {
            id
            enlarge
            center
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulSpacer {
          __typename
          id
          spacing
          internal {
            type
          }
        }
        ... on ContentfulPeopleModule {
          id
          roles {
            ... on ContentfulRole {
              roleName
              people {
                name
              }
              internal {
                type
              }
            }
          }
          internal {
            type
          }
        }
      }
    }
  }
`
