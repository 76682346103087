import React from "react"
import { Box } from "@theme-ui/components"
import RichText from "./text/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"

const PeopleModule = ({ data, padTop, padBottom }) => {
  const cols = data.roles.length < 4 ? data.roles.length : 4

  return (
    <Box
      className="peo-mod"
      mx="auto"
      pt={padTop ? [4, 6] : [2]}
      pb={padBottom ? [4, 6] : [2]}
      px={[3, "40px"]}
      sx={{
        maxWidth: "1200px",
        width: "100%",
        display: "grid",
        gridTemplateColumns: [data.roles[0]?.description ? "1fr" : "1fr 1fr", "1fr 1fr",`repeat(${cols}, 1fr)`],
        gridRowGap: "25px",
        gridColumnGap: "25px",
      }}
    >
      {data.roles.map((role, idx) => {
        if (role.internal.type === "ContentfulPerson") {
          return (
            <Box key={idx}>
              {role.images?.[0] && <Box mb={2}>
                <GatsbyImage style={{ maxHeight: "20px" }} imgStyle={{ objectPosition: 'left' , objectFit: 'contain' }} image={role.images[0].gatsbyImageData} alt={role.name} />
              </Box>}
              <Box
                sx={{
                  fontFamily: "pragmatica-extended, sans-serif",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "13px",
                }}
              >
                {role.name}
              </Box>
              <Box
                mb={"5px"}
                sx={{
                  fontSize: "10px",
                  lineHeight: "13px",
                  fontStyle: "italic",
                }}
              >
                {role.title}
              </Box>
              {role.email && (
                <Box
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                    lineHeight: "14px",
                    a: {
                      textDecoration: "none",
                    },
                  }}
                >
                  <a href={`mailto:${role.email}`}>{role.email}</a>
                </Box>
              )}
              {role.phone && (
                <Box
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                    lineHeight: "14px",
                    a: {
                      textDecoration: "none",
                    },
                  }}
                >
                  M: <a href={`tel:${role.phone}`}>{role.phone}</a>
                </Box>
              )}
              {role.description && (
                <Box mt={2}>
                  <RichText sx={{fontStyle: 'italic', fontSize: '9px'}} html={role.description.childMarkdownRemark.html} />
                </Box>
              )}
            </Box>
          )
        }

        return (
          <Box>
            <Box
              sx={{
                fontFamily: "pragmatica-extended, sans-serif",
                fontWeight: "400",
                textTransform: "uppercase",
                fontSize: "11px",
              }}
            >
              {role.roleName}
            </Box>
            <Box sx={{ fontFamily: "Formular", fontWeight: 300 }}>
              {role.people.map(({ name }) => name).join(", ")}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default PeopleModule
